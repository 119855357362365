import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Map from './components/Map/Map';
import Sidebar from './components/Sidebar/Sidebar';

import { ActiveButtonProvider } from './hooks/ActiveButtonContext';

function App() {

  return (
    <div className="app">
      <div className='app_layer'>
        <div className='header'>
          <Header />
        </div>
        <div className='map'>
          <ActiveButtonProvider>
            <Sidebar />
            <Map />
          </ActiveButtonProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
