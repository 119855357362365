import { MarkerUtils } from "@googlemaps/markerclusterer";

export function createMarker(map: google.maps.Map, lat: number, lng: number) {
  if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
    return new google.maps.marker.AdvancedMarkerElement({
      map,
      position: { lat, lng },
    });
  }

  return new google.maps.Marker({
    position: { lat, lng },
    map,
  });
}


export function convertToLatLng(points: any[]) {
  return points.map((point: any[]) => ({
    lat: point[0],
    lng: point[1]
  }));
}


export function markerIcon(activeButton: number) {
  return {
    url: `data:image/svg+xml;base64,${btoa(`
        <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30 15C30 20.6707 22.9715 30.0579 18.5368 35.4165C16.6784 37.6619 13.3215 37.6619 11.4632 35.4164C7.02851 30.0579 0 20.6707 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill="${getColorForActiveButton(activeButton)}"/>
          </svg>
      `)}`
  };
}


export function getColorForActiveButton(activeButton: number) {
  switch (activeButton) {
    case 1:
      return "#FFB500";
    case 2:
      return "#6D36DB";
    case 3:
      return "#00C2CB";
    default:
      return "#1A0A3A";
  }
}