import React, { useState, useEffect, useContext, useRef } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { convertToLatLng, markerIcon, getColorForActiveButton } from "../../utils/map.ts";
import { ActiveButtonContext } from '../../hooks/ActiveButtonContext';
import mapStyle from "../../assets/styles/mapStyle.json";
import parkingData from "../../assets/parkings.json";

import ParkingDialog from '../Dialog/ParkingDIalog.js'; // Importamos el componente del diálogo


const defaultCenter = {
  lat: 25.7617,
  lng: -80.1918
};


function Map() {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [userLocation, setUserLocation] = useState(null); // Guardar la ubicación del usuario


  const [selectedParking, setSelectedParking] = useState(null); // Estado para el parking seleccionado
  const [isDialogOpen, setDialogOpen] = useState(false); // Estado para el diálogo


  const { activeButton } = useContext(ActiveButtonContext);

  const clustererRef = useRef(null);
  const polygonsRef = useRef([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB0Sty-X2VYAt0Hypu-zwUQ_pPT3-U-678", // Reemplaza con tu API Key de Google Maps
  });


  useEffect(() => {
    // Verificamos si el navegador soporta Geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setCenter(userLocation);
          setUserLocation({ lat: userLocation.lat, lng: userLocation.lng });
        },
        () => {
          console.error("Error al obtener la ubicación");
        }
      );
    } else {
      console.error("Geolocation no está soportado por este navegador.");
    }
  }, []);

  const clearMapElements = () => {
    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
      clustererRef.current = null;
    }

    polygonsRef.current.forEach(polygon => polygon.setMap(null));
    polygonsRef.current = [];
  };


  useEffect(() => {
    if (isLoaded) {
      clearMapElements();

      const getFilteredParkingData = () => {
        switch (activeButton) {
          case 1:
            return parkingData.filter(parking => parking.type.includes("public"));
          case 2:
            return parkingData.filter(parking => parking.type.includes("private"));
          case 3:
            return parkingData.filter(parking => parking.type.includes("ev"));
          default:
            return parkingData;
        }
      };

      const customClusterIconRenderer = ({ count, position }) => {
        const svgIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <circle cx="35" cy="35" r="34" fill="${getColorForActiveButton(activeButton)}" fill-opacity="0.4"/>
        <circle cx="35" cy="35" r="28" fill="${getColorForActiveButton(activeButton)}" fill-opacity="0.6" />
        <circle cx="35" cy="35" r="22" fill="${getColorForActiveButton(activeButton)}" fill-opacity="0.8"/>
      </svg>
    `;

        const marker = new window.google.maps.Marker({
          position,
          label: {
            text: String(count),
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "Barlow"
          },
          icon: {
            url: `data:image/svg+xml;base64,${btoa(svgIcon)}`,
            scaledSize: new window.google.maps.Size(50, 50),
          },
        });
        marker.addListener("click", () => {
          if (map) {
            map.panTo(marker.getPosition());
            map.setZoom(17);
          }
        });

        return marker;
      };


      const filteredParkingData = getFilteredParkingData();


      const newClusterer = new MarkerClusterer({
        map: map,
        renderer: {
          render: (params) => customClusterIconRenderer(params),
        },
      });

      const markers = filteredParkingData.map(parking => {
        const marker = new window.google.maps.Marker({
          position: { lat: parking.position[0], lng: parking.position[1] },
          icon: markerIcon(activeButton),
          label: {
            text: String(parking.available),
            color: "white",
            fontSize: "16px",
            fontFamily: "Barlow"
          },
        });

        marker.addListener("click", () => {
          setSelectedParking(parking);  // Guardamos el parking seleccionado
          setDialogOpen(true); // Abrimos el diálogo
          if (map) {
            map.panTo(marker.getPosition());
            map.setZoom(17);
          }
        });

        return marker;
      });

      newClusterer.addMarkers(markers);
      clustererRef.current = newClusterer;


      filteredParkingData.forEach((parking) => {
        const polygon = new window.google.maps.Polygon({
          paths: convertToLatLng(parking.polygonPoints),
          map: map,
          fillColor: getColorForActiveButton(activeButton),
          fillOpacity: .2,
          strokeWeight: 0,
          clickable: false,
          zIndex: 1
        });
        polygonsRef.current.push(polygon);
      });
    }
  }, [isLoaded, activeButton, map]);

  if (!isLoaded) return <div>Cargando...</div>;

  return (
    <>
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{
          width: "100%",
          height: "100%"
        }}
        onLoad={(mapInstance) => setMap(mapInstance)}
        options={{
          styles: mapStyle,
          minZoom: 10,
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          gestureHandling: "greedy",
        }}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 10,
              fillColor: "#1A0A3A",
              fillOpacity: 1,
              strokeWeight: 3,
              strokeColor: "white"
            }}
          />
        )}
      </GoogleMap>
      {/* Mostramos el diálogo cuando se selecciona un parking */}
      <ParkingDialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}  // Función para cerrar el diálogo
        parking={selectedParking}  // Enviamos la información del parking seleccionado
      />
    </>
  );
}

export default Map;
