import React, { createContext, useState } from 'react';

// Creamos el contexto
export const ActiveButtonContext = createContext();

// Creamos un proveedor de contexto
export const ActiveButtonProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState(null);

  return (
    <ActiveButtonContext.Provider value={{ activeButton, setActiveButton }}>
      {children}
    </ActiveButtonContext.Provider>
  );
};
