import React, { useContext } from 'react';
import './Sidebar.css';
import parkingIcon from '../../assets/icons/parking.svg';
import moneyIcon from '../../assets/icons/money.svg';
import evIcon from '../../assets/icons/ev.svg';
import { ActiveButtonContext } from '../../hooks/ActiveButtonContext'; // Importamos el contexto


function Sidebar() {
  const { activeButton, setActiveButton } = useContext(ActiveButtonContext); // Accedemos al contexto

  return (
    <div className="sidebar">
      <button
        className={`sidebar__button ${activeButton === 1 ? 'active' : ''}`}
        onClick={() => (activeButton === 1) ? setActiveButton(null) : setActiveButton(1)}
      >
        <img src={parkingIcon} alt="Public Parking" />
      </button>
      <button
        className={`sidebar__button ${activeButton === 2 ? 'active' : ''}`}
        onClick={() => (activeButton === 2) ? setActiveButton(null) : setActiveButton(2)}

      >
        <img src={moneyIcon} alt="Private Parking" />
      </button>
      <button
        className={`sidebar__button ${activeButton === 3 ? 'active' : ''}`}
        onClick={() => (activeButton === 3) ? setActiveButton(null) : setActiveButton(3)}

      >
        <img src={evIcon} alt="Electric Vehicle Parking" />
      </button>
    </div>
  );
}

export default Sidebar;
