import React from 'react';
import './Header.css';
import logo from '../../assets/icons/logo.svg';
import searchIcon from '../../assets/icons/search.svg';

function Header() {
  return (
    <div className="header">

      <div className='header__presentation'>
        <a href='https://smartparkpro.com' alt="SmartPark Pro">
          <img src={logo} alt="SmartPark Pro Logo" className="header__logo" />
          <h1 className="header__title"><b>SmartPark</b> Pro</h1>
        </a>
      </div>

      <div className='header__actions'>
        <button className='header__button'>
          <img src={searchIcon} alt='Search' />
        </button>
      </div>
    </div>
  );
}

export default Header;
