import React from "react";
import './ParkingDialog.css';
import clockIcon from "../../assets/icons/clock.svg";
import chargerIcon from "../../assets/icons/charger.svg";
import spacingIcon from "../../assets/icons/spacing.svg";
import slotsIcon from "../../assets/icons/slots.svg";
import priceIcon from "../../assets/icons/price.svg";


function ParkingDialog({ open, onClose, parking }) {
    if (!open || !parking) return null;

    return (
        <div className="dialog-backdrop" onClick={onClose}>
            <div className="dialog-content" onClick={(e) => e.stopPropagation()}>

                <div class="details-header">
                    <div className="details-title">
                        <span class="name">{parking.details.name}</span>
                        <button onClick={onClose}>X</button>
                    </div>
                    <span class="address">{parking.details.address}</span>
                    <span class="type">Public</span>
                </div>

                <div class="details-section">
                    {parking.details.price && (
                        <div className="detail-item">
                            <img src={priceIcon} alt="24 hours" class="icon" />
                            <span>${parking.details.price.toFixed(2)}/hr</span>
                        </div>
                    )}
                    <div class="detail-item">
                        <img src={clockIcon} alt="24 hours" class="icon" />
                        <span>{parking.details.time}</span>
                    </div>
                    <div class="detail-item">
                        <img src={spacingIcon} alt="Height" class="icon" />
                        <span>{parking.details.height}</span>
                    </div>
                    {parking.type.includes('ev') && (
                        <div className="detail-item">
                            <img src={chargerIcon} alt="Electric charged" className="icon" />
                            <span>Electric charged</span>
                        </div>
                    )}
                    <div class="detail-item">
                        <img src={slotsIcon} alt="Electric charged" class="icon" />
                        <span>{parking.available}/{parking.details.totalSlots}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ParkingDialog;
